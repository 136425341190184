import Joyride, { CallBackProps } from 'react-joyride';
import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { Link, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useAppContext } from '../../contexts/GuidedOnboardingContext';
import useRestartTour from 'hooks/useRestartTour.hooks';
import useAuth from 'hooks/useAuth';

// import assets
import ManageRoutesPageMP4 from "./../../assets/videos/ManageRoutesPage.mp4";
import ManageRoutesPageWEBM from "./../../assets/videos/ManageRoutesPage.webm";
import programs_1 from './../../assets/images/guided-onboarding/programs_1.png';
import users_1 from './../../assets/images/guided-onboarding/users_1.png';

export default function GuidedOnboardingWrapper() {
    const { user } = useAuth();
    const {
        setState,
        state: { run, steps },
    } = useAppContext();
    const navigate = useNavigate();
    const theme = useTheme();
    const {endTour} = useRestartTour();
    
    useEffect(() => {
        if (user && user.show_guided_onboarding) {
            if (user && user.role === "Admin") {
                setState({steps: [
                    {
                        title: 'Welcome to your dashboard!',
                        content: 'Here, you can easily monitor key metrics, track activities, and access essential tools. Stay organized and informed with an overview of your operations, all in one place.',
                        locale: { next: 'GET STARTED' },
                        target: 'body',
                        placement: 'center' as const,
                        disableBeacon: true,
                    },
                    {
                        target: "#dashboard-activity-feed",
                        content: "Stay updated with the activity feed, showing the latest actions and updates on the platform.",
                    },
                    {
                        target: "#dashboard-upcoming-routes",
                        content: "Quickly see your next five scheduled food rescue routes and spot any that need attention. Click any row to view the details.",
                    },
                    {
                        target: "#dashboard-impact-metrics",
                        content: "Explore your organization's summarized impact statistics to understand the positive effects you're making on the community and environment.",
                    },
                    {
                        target: "#dashboard-recipient-donor-submissions",
                        content: "Easily manage and review new donor and recipient applications in one convenient location.",
                        placement: 'left' as const,
                    },
                    {
                        target: "#dashboard-photo-feed",
                        content: "Browse through a photo feed showcasing recent food rescue pickups.",
                        placement: 'left' as const,
                        data: {
                            next: '/routes',
                        },
                    },
                    {
                        title: "Welcome to the Routes section",
                        content: "Efficiently plan, schedule, and monitor all food rescue routes.",
                        target: 'body',
                        placement: 'center' as const,
                        data: {
                            prev: '/dashboard',
                        },
                    },
                    {
                        content: <Stack direction="row" gap={4}>
                                    <video muted autoPlay loop style={{ pointerEvents: 'none', }} width="576px" height="330px" >
                                        <source src={ManageRoutesPageWEBM} type="video/webm"/>
                                        <source src={ManageRoutesPageMP4} type="video/mp4"/>
                                    </video>

                                    <span style={{ width: "18em" }}>
                                        Simplify your scheduling with recurring routes, designed for regular, repeatable food rescue operations.<br/><br/>
                                        Selecting a route will reveal a list of instances scheduled and performed for that route.<br/><br/>
                                        Customize and oversee each unique route to accommodate specific needs and circumstances.<br/><br/>
                                        View data collected by delivery drivers in real time as food rescue routes are created.
                                    </span>
                                </Stack>,
                        target: 'body',
                        placement: 'center' as const,
                        styles: { options: {width: '400'}},
                        data: {
                            next: '/donors',
                        },
                    },
                    {
                        title: "Food Donors, Food Recipients and Delivery Drivers",
                        content: <Stack sx={{ alignItems: "center" }}>
                                    View and update detailed profiles of your food donors, food recipients and delivery drivers.
                                    <br/>
                                    <img src={users_1} alt="User Datagrid"></img>
                                </Stack>,
                        target: 'body',
                        placement: 'center' as const,
                        styles: { options: {width: '200'}},
                        data: {
                            prev: '/routes',
                            next: '/report/route',
                        },
                    },
                    {
                        title: "Reports",
                        content: "Generate tailored reports to extract the precise data you need.",
                        target: 'body',
                        placement: 'center' as const,
                        data: {
                            prev: '/donors',
                            next: '/programs',
                        },
                    },
                    {
                        title: "Programs",
                        content: <Stack sx={{ alignItems: "center" }}>
                                    Create multiple programs with distinct settings and customized survey questions to ensure you collect the precise information needed for each initiative.
                                    <br/>
                                    <img style={{ width: "36em" }} src={programs_1} alt="Program Datagrid"></img>
                                </Stack>,
                        target: 'body',
                        placement: 'center' as const,
                        styles: { options: {width: '200'}},
                        data: {
                            prev: '/report/route',
                            next: '/organization',
                        },
                    },
                    {
                        title: "Organization Settings",
                        content: "Here you can set the defaults for your entire organization.",
                        target: 'body',
                        placement: 'center' as const,
                        data: {
                            prev: '/programs',
                        },
                    },
                    {
                        content: "This is how your organization will show up to your users.",
                        target: '#organization-name-label',
                        spotlightClicks: true,
                        placement: 'top' as const,
                    },
                    {
                        content: "Add and customize a waiver to be signed by new delivery drivers. Please look into the legal requirements and best practices for your organization.",
                        target: '#waiver-label',
                        spotlightClicks: true,
                    },
                    {
                        content: "Choose the default country and units of measurement to streamline logistics.",
                        target: '#units-and-country-label',
                        spotlightClicks: true,
                        spotlightPadding: 0,
                    },
                    {
                        content: "Add your unique organization logo.",
                        target: '#logo-settings',
                        spotlightClicks: true,
                        spotlightPadding: 0,
                    },
                    {
                        content: "Customize the mobile app theme for your delivery drivers.",
                        target: '#theme-settings',
                        spotlightClicks: true,
                        spotlightPadding: 0,
                    },
                    {
                        content: "Easily track updates by customizing when admins will receive email notifications.",
                        target: '#notification-settings',
                        spotlightClicks: true,
                    },
                    {
                        content: <span>
                                    Nice! You are all set.
                                    <br/><br/>
                                    If you need help with anything else, you can always check out our <Link href={"https://kneadtech.freshdesk.com/support/home"}>Customer Success Portal</Link>.
                                </span>,
                        target: '#save-settings-button',
                        spotlightClicks: true,
                        showSkipButton: false,
                        locale: { last: 'FINISH' },
                    },
                ]});
            } else {
                // Future TODO: expand product tours for non-admin user types.
                setState({steps: [
                    {
                        title: 'Welcome to your Knead dashboard!',
                        content: 'Stay organized and informed with an overview of your operations, all in one place.',
                        target: 'body',
                        placement: 'center' as const,
                        disableBeacon: true,
                        showSkipButton: false,
                        locale: { last: 'OK' },
                    },
                ]});
            }
        }
    }, [user ? user.show_guided_onboarding : user]) // Handle the login page where the user is null

    const handleCallback = (data: CallBackProps) => {
        const { action, type, step } = data;

        /* Take action after viewing the tour step:
        *      - The "Next" button triggers a "next" action
        *      - The "Back" button triggers a "prev" action
        */
        if (type==="step:after") {
            if (action==="next" && step.data && step.data.next) {
                navigate(step.data.next);
            } else if (action==="prev" && step.data && step.data.prev) {
                navigate(step.data.prev);
            }
        }

        // Note: action === 'close' should not end the tour
        if (action === 'skip' || type === 'tour:end'){
            setState({run: false, tourActive: false});
            handleEndTour(); // Turn off the show_guided_onboarding flag
        }
    };

    const handleEndTour = async () => {
        await endTour(user.id); // Call the hook's function
        user.show_guided_onboarding = false;
    };

    return (
        <div>
            <Outlet />
            <Joyride
                callback={handleCallback}
                continuous
                scrollToFirstStep
                showSkipButton
                hideCloseButton
                run={run}
                steps={steps}
                locale={{ skip: 'EXIT PRODUCT TOUR' }}
                styles={{
                    options: {
                        primaryColor: theme.palette.primary.main,
                        zIndex: 3001,
                    },
                    buttonSkip: {
                        color: theme.palette.primary.main,
                    },
                    tooltipContent: {
                        textAlign: 'left',
                    },
                }}
            />
        </div>
    );
}