import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/donor/dashboard')));
const RecipientRoutineRoutesPage = Loadable(lazy(() => import('views/recipient/routine-donations/RecipientRoutineRoutesPage')));
const RecipientOnDemandRoutesPage = Loadable(lazy(() => import('views/recipient/on-demand-donations/RecipientOnDemandRoutesPage')));

//update password
const UpdatePasswordPage = Loadable(lazy(() => import('views/shared/update-password')));
// ==============================|| MAIN ROUTING ||============================== //

const DonorRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/upcoming-routine-donations',
            element: <RecipientRoutineRoutesPage upcoming={true} key="routine-upcoming" />,
        },
        {
            path: '/past-routine-donations',
            element: <RecipientRoutineRoutesPage upcoming={false} key="routine-past" />,
        },
        {
            path: '/upcoming-on-demand-donations',
            element: <RecipientOnDemandRoutesPage upcoming={true} key="on-demand-upcoming" />,
        },
        {
            path: '/past-on-demand-donations',
            element: <RecipientOnDemandRoutesPage upcoming={false} key="on-demand-past" />,
        },
        {
            path: '/user/update-password',
            element: <UpdatePasswordPage />
        },
    ]
};

export default DonorRoutes;
