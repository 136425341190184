import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/donor/dashboard')));
const DonorRoutineRoutesPage = Loadable(lazy(() => import('views/donor/routine-donations/DonorRoutineRoutesPage')));
const DonorOnDemandRoutesPage = Loadable(lazy(() => import('views/donor/on-demand-donations/DonorOnDemandRoutesPage')));
const CreateOnDemandRoutePage = Loadable(lazy(() => import('views/donor/on-demand-donations/CreateOnDemandRoutePage')));
const CreateGrowerRoutePage = Loadable(lazy(() => import('views/donor/on-demand-donations/CreateGrowerRoutePage')));

//update password
const UpdatePasswordPage = Loadable(lazy(() => import('views/shared/update-password')));
// ==============================|| MAIN ROUTING ||============================== //

const DonorRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/upcoming-routine-donations',
            element: <DonorRoutineRoutesPage upcoming={true} key="routine-upcoming" />,
        },
        {
            path: '/past-routine-donations',
            element: <DonorRoutineRoutesPage upcoming={false} key="routine-past" />,
        },
        {
            path: '/upcoming-on-demand-donations',
            element: <DonorOnDemandRoutesPage upcoming={true} key="on-demand-upcoming" />,
        },
        {
            path: '/past-on-demand-donations',
            element: <DonorOnDemandRoutesPage upcoming={false} key="on-demand-past" />,
        },
        {
            path: '/create-on-demand-donation',
            element: <CreateOnDemandRoutePage key="create" />,
        },
        {
            path: '/create-on-demand-pickup',
            element: <CreateGrowerRoutePage harvestAndPickup={false} key="pickup-only" />,
        },
        {
            path: '/create-on-demand-harvest',
            element: <CreateGrowerRoutePage harvestAndPickup={true} key="harvest-and-pickup" />,
        },
        {
            path: '/user/update-password',
            element: <UpdatePasswordPage />
        },
    ]
};

export default DonorRoutes;
