// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconFileAnalytics } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconFileAnalytics
};
const reports: NavItemType = {
	id: 'reports-section',
	icon: icons.IconFileAnalytics,
	type: 'group',
	children: [
			{
					id: 'reports',
					title: <FormattedMessage id="Reports" />,
					type: 'collapse',
					icon: icons.IconFileAnalytics,
					children: [
							{
									id: 'monthlyReport',
									title: <FormattedMessage id="Monthly Report" />,
									type: 'item',
									url: '/report/monthly',
									target: false
							}
					]
			}
	]
};

export default reports;
