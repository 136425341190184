import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// main page routing
const Dashboard = Loadable(lazy(() => import('views/admin/dashboard')));
const RoutesPage = Loadable(lazy(() => import('views/admin/routes-section/view-routes')));
const RoutesCreatePage = Loadable(lazy(() => import('views/admin/routes-section/create-routes')));
const RoutesEditPage = Loadable(lazy(() => import('views/admin/routes-section/edit-routes')));
const AchievementsPage = Loadable(lazy(() => import('views/admin/achievements-section/admin-achievements-screen')));
const AchievementPage = Loadable(lazy(() => import('views/admin/achievements-section/admin-achievement-screen')));
const PrizesPage = Loadable(lazy(() => import('views/admin/prizes/admin-prizes-screen')));
const PrizePage = Loadable(lazy(() => import('views/admin/prizes/admin-prize-screen')));
const PrizesQueuePage = Loadable(lazy(() => import('views/admin/prizes-queue/admin-prizes-queue-screen')));
const FrequentlyAskedQuestionsPage = Loadable(lazy(() => import('views/admin/frequently-asked-questions')));
const RouteInstancesByDatePage = Loadable(lazy(() => import('views/admin/routes-section/view-route-instances-by-date')));
const RouteReportPage = Loadable(lazy(() => import('views/admin/reports-section/report-route')));
const ProfileReportPage = Loadable(lazy(() => import('views/admin/reports-section/report-profile')));

const ProgramsPage = Loadable(lazy(() => import('views/admin/programs-section/admin-programs-screen')));
const ArchivedProgramsPage = Loadable(lazy(() => import('views/admin/programs-section/admin-archived-programs-screen')));
const ProgramPage = Loadable(lazy(() => import('views/admin/programs-section/admin-program-screen')));

// home harvest route instance creation (for Leftovers and Generous Hearts only)
const HHRouteInstanceCreatePage = Loadable(lazy(() => import('views/admin/routes-section/create-harvest-route-instance')));


//organization
const OrganizationSettingsPage = Loadable(lazy(() => import('views/admin/organization/organization-settings')));
const AdministratorsPage = Loadable(lazy(() => import('views/admin/organization/administrator-section/view-users')));
const AdministratorCreatePage = Loadable(lazy(() => import('views/admin/organization/administrator-section/create-user')));
const AdministratorEditPage = Loadable(lazy(() => import('views/admin/organization/administrator-section/edit-user')));
const FoodProductsPage = Loadable(lazy(() => import('views/admin/organization/food-products-section/view-food-products/FoodProductsPage')));
const ArchivedFoodProductsPage = Loadable(lazy(() => import('views/admin/organization/food-products-section/view-food-products/ArchivedFoodProductsPage')));

//donor
const DonorsPage = Loadable(lazy(() => import('views/admin/donor-section/view-users')));
const DonorsApplicationsPage = Loadable(lazy(() => import('views/admin/donor-section/applications')));
const DonorCreatePage = Loadable(lazy(() => import('views/admin/donor-section/create-user')));
const DonorEditPage = Loadable(lazy(() => import('views/admin/donor-section/edit-user')));

//recipient
const RecipientsPage = Loadable(lazy(() => import('views/admin/recipient-section/view-users')));
const RecipientsApplicationsPage = Loadable(lazy(() => import('views/admin/recipient-section/applications')));
const RecipientCreatePage = Loadable(lazy(() => import('views/admin/recipient-section/create-user')));
const RecipientEditPage = Loadable(lazy(() => import('views/admin/recipient-section/edit-user')));

//delivery-driver
const DeliveryDriversPage = Loadable(lazy(() => import('views/admin/delivery-driver-section/view-users')));
const DeliveryDriverCreatePage = Loadable(lazy(() => import('views/admin/delivery-driver-section/create-user')));
const DeliveryDriverEditPage = Loadable(lazy(() => import('views/admin/delivery-driver-section/edit-user')));

//regions
const RegionsPage = Loadable(lazy(() => import('views/admin/regions-section/view-regions')));
const RegionCreatePage = Loadable(lazy(() => import('views/admin/regions-section/create-region')));
const RegionEditPage = Loadable(lazy(() => import('views/admin/regions-section/edit-region')));

//update password
const UpdatePasswordPage = Loadable(lazy(() => import('views/shared/update-password')));
// ==============================|| ADMIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/routes',
            element: <RoutesPage />
        },
        {
            path: '/routes/create',
            element: <RoutesCreatePage />
        },
        {
            path: '/routes/edit',
            element: <RoutesEditPage />
        },
        {
            path: '/route-instance/home-harvest/pickup',
            element: <HHRouteInstanceCreatePage harvestAndPickup={false} key="pickup-only" />
        },
        {
            path: '/route-instance/home-harvest/harvest',
            element: <HHRouteInstanceCreatePage harvestAndPickup={true} key="harvest-and-pickup" />
        },
        {
            path: '/achievements',
            element: <AchievementsPage />
        },
        {
            path: '/achievements/:achievementId',
            element: <AchievementPage />
        },
        {
            path: '/achievements/create',
            element: <AchievementPage />
        },
        {
            path: '/prizes',
            element: <PrizesPage />
        },
        {
            path: '/prizes/:prizeId',
            element: <PrizePage />
        },
        {
            path: '/prizes/create',
            element: <PrizePage />
        },
        {
            path: '/prize-queue',
            element: <PrizesQueuePage />
        },
        {
            path: '/organization',
            element: <OrganizationSettingsPage />
        },
        {
            path: '/administrators',
            element: <AdministratorsPage />
        },
        {
            path: '/administrator/create',
            element: <AdministratorCreatePage />
        },
        {
            path: '/administrator/edit',
            element: <AdministratorEditPage />
        },
        {
            path: '/frequently-asked-questions',
            element: <FrequentlyAskedQuestionsPage />
        },
        {
            path: '/route-instances-by-date',
            element: <RouteInstancesByDatePage />
        },
        {
            path: '/donors',
            element: <DonorsPage />
        },
        {
            path: '/donors/applications',
            element: <DonorsApplicationsPage />
        },
        {
            path: '/donor/create',
            element: <DonorCreatePage />
        },
        {
            path: '/donor/edit',
            element: <DonorEditPage />
        },
        {
            path: '/recipients',
            element: <RecipientsPage />
        },
        {
            path: '/recipients/applications',
            element: <RecipientsApplicationsPage />
        },
        {
            path: '/recipient/create',
            element: <RecipientCreatePage />
        },
        {
            path: '/recipient/edit',
            element: <RecipientEditPage />
        },
        {
            path: '/delivery-drivers',
            element: <DeliveryDriversPage />
        },
        {
            path: '/delivery-driver/create',
            element: <DeliveryDriverCreatePage />
        },
        {
            path: '/delivery-driver/edit',
            element: <DeliveryDriverEditPage />
        },
        {
            path: '/report/route',
            element: <RouteReportPage />
        },
        {
            path: '/report/profile',
            element: <ProfileReportPage />
        },
        {
            path: '/regions',
            element: <RegionsPage />
        },
        {
            path: '/region/create',
            element: <RegionCreatePage />
        },
        {
            path: '/region/edit',
            element: <RegionEditPage />
        },
        {
            path: '/user/update-password',
            element: <UpdatePasswordPage />
        },
        {
            path: '/programs',
            element: <ProgramsPage />
        },
        {
            path: '/programs/edit',
            element: <ProgramPage />
        },
        {
            path: '/programs/create',
            element: <ProgramPage />
        },
        {
            path: '/archivedPrograms',
            element: <ArchivedProgramsPage />
        },
        {
            path: '/food-products',
            element: <FoodProductsPage />
        },
        {
            path: '/archived-food-products',
            element: <ArchivedFoodProductsPage />
        },
    ]
};

export default AdminRoutes;
