// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconRoute2 } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconRoute2
};
const routes: NavItemType = {
    id: 'routes-section',
    icon: icons.IconRoute2,
    type: 'group',
    children: [
        {
            id: 'routes',
            title: <FormattedMessage id="Routes" />,
            type: 'collapse',
            icon: icons.IconRoute2,
            children: [
                {
                    id: 'my-routes',
                    title: <FormattedMessage id="View My Upcoming Routes" />,
                    type: 'item',
                    url: '/my-upcoming-routes',
                    target: false
                },
								{
                    id: 'my-past-routes',
                    title: <FormattedMessage id="View My Past Routes" />,
                    type: 'item',
                    url: '/my-past-routes',
                    target: false
                },
                {
                    id: 'available-routes',
                    title: <FormattedMessage id="View Available Routes" />,
                    type: 'item',
                    url: '/available-routes',
                    target: false
                }
            ]
        }
    ]
};

export default routes;
