// project import
import dashboard from './dashboard';
import routes from './routes';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, routes]
};

export default menuItems;
