// project import
import dashboard from './dashboard';
import organizations from './organizations';
import reports from './reports';

// types
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [dashboard, organizations, reports]
};

export default menuItems;
