// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconRoute2 } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconRoute2
};
const routineDonations: NavItemType = {
    id: 'routes-section',
    title: <FormattedMessage id="Routine Donations" />,
    icon: icons.IconRoute2,
    type: 'group',
    children: [
        {
            id: 'routes',
            title: <FormattedMessage id="Routine Donations" />,
            type: 'collapse',
            icon: icons.IconRoute2,
            children: [
                {
                    id: 'upcoming-routine-donations',
                    title: <FormattedMessage id="Upcoming Routine Donations" />,
                    type: 'item',
                    url: '/upcoming-routine-donations',
                    target: false
                },
								{
                    id: 'past-routine-donations',
                    title: <FormattedMessage id="Past Routine Donations" />,
                    type: 'item',
                    url: '/past-routine-donations',
                    target: false
                }
            ]
        }
    ]
};

export default routineDonations;
