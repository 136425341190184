import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Login3')));
const AuthSelectOrganization = Loadable(lazy(() => import('views/pages/authentication/SelectOrganization')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/Register3')));
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/ForgotPassword3')));
const AuthResetPassword = Loadable(lazy(() => import('views/pages/authentication/ResetPassword3')));
const AuthRegistrationPending = Loadable(lazy(() => import('views/pages/authentication/RegistrationPending')));
const AuthDeleteAccount = Loadable(lazy(() => import('views/pages/authentication/DeleteAccount')));
const AuthRegisterOrganization = Loadable(lazy(() => import('views/pages/authentication/RegisterOrganization')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/registration-pending',
            element: <AuthRegistrationPending/>
        },
        {
            path: '/selectOrganization',
            element: <AuthSelectOrganization />
        },
        {
            path: '/register/:organizationID',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/reset',
            element: <AuthResetPassword />
        },
        {
            path: '/deleteAccount',
            element: <AuthDeleteAccount />
        },
        {
            path: '/registerOrganization',
            element: <AuthRegisterOrganization />
        },
    ]
};

export default LoginRoutes;
