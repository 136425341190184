// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHomeDown } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconHomeDown
};
const recipient: NavItemType = {
    id: 'recipient',
    title: <FormattedMessage id="Recipient" />,
    icon: icons.IconHomeDown,
    type: 'group',
    url: '/recipients'
};

export default recipient;
