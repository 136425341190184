import { useRoutes, RouteObject } from 'react-router-dom';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import LoginRoutes from './LoginRoutes';
import AdminRoutes from './AdminRoutes';
import DeliveryDriverRoutes from './DeliveryDriverRoutes';
import DonorRoutes from './DonorRoutes';
import RecipientRoutes from './RecipientRoutes';
import SuperuserRoutes from './SuperuserRoutes';
import ErrorRoutes from './ErrorRoutes';

import useAuth from 'hooks/useAuth';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { isLoggedIn, user } = useAuth();

    var routes:RouteObject[] = [LoginRoutes, AuthenticationRoutes];;
    if (isLoggedIn && user.role === 'Admin')
    {
      routes.push(AdminRoutes);
    }
    else if (isLoggedIn && user.role == 'Delivery Driver')
    {
      routes.push(DeliveryDriverRoutes);
    }
    else if (isLoggedIn && user.role == 'Food Donor')
    {
      routes.push(DonorRoutes);
    }
    else if (isLoggedIn && user.role == 'Recipient')
    {
      routes.push(RecipientRoutes);
    }
    else if (isLoggedIn && user.role == 'Superuser')
    {
      routes.push(SuperuserRoutes);
    }
    
    routes.push(ErrorRoutes);
    
    return useRoutes(routes);
}
