// material-ui
import { useTheme } from '@mui/material/styles';
import useAuth from 'hooks/useAuth';
import LoggedInLogo from './LoggedInLogo';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
import KNE_Wordmark_Cream from 'assets/images/KNE_Wordmark_Cream.png';
import KNE_Wordmark_Black from 'assets/images/KNE_Wordmark_Black.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();
    const { user, isLoggedIn } = useAuth();
    if (!isLoggedIn)
    {
      return <img src={theme.palette.mode === 'dark' ? KNE_Wordmark_Cream : KNE_Wordmark_Black} alt="Knead Tech" width="100"></img>
    }
    
    return <LoggedInLogo user={user}/>
};

export default Logo;
