import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/superuser/dashboard')));

const OrganizationsScreen = Loadable(lazy(() => import('views/superuser/organizations/OrganizationsScreen')));
const ArchivedOrganizationsScreen = Loadable(lazy(() => import('views/superuser/organizations/ArchivedOrganizationsScreen')));
const OrganizationScreen = Loadable(lazy(() => import('views/superuser/organizations/OrganizationScreen')));

const MonthlyReportPage = Loadable(lazy(() => import('views/superuser/reports/MonthlyReport')));
//const OrganizationScreen = Loadable(lazy(() => import('views/superuser/organization/OrganizationScreen')));

//update password
const UpdatePasswordPage = Loadable(lazy(() => import('views/shared/update-password')));
// ==============================|| MAIN ROUTING ||============================== //

const SuperuserRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <Dashboard />
        },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/organizations',
            element: <OrganizationsScreen />,
        },
        {
            path: '/archivedOrganizations',
            element: <ArchivedOrganizationsScreen />,
        },
        {
            path: '/organizations/edit',
            element: <OrganizationScreen />
        },
        {
            path: '/organizations/create',
            element: <OrganizationScreen />
        },
        {
            path: '/report/monthly',
            element: <MonthlyReportPage />,
        },
        {
            path: '/user/update-password',
            element: <UpdatePasswordPage />
        },
    ]
};

export default SuperuserRoutes;
