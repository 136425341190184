// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconFileStack } from '@tabler/icons';

// type
import { NavItemType } from 'types';

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //

const icons = {
    IconFileStack
};
const programs: NavItemType = {
  id: 'programsSection',
  title: <FormattedMessage id="Programs" />,
  type: 'group',
  children: [
              {
                  id: 'programs',
                  title: <FormattedMessage id="Programs" />,
                  type: 'item',
                  icon: icons.IconFileStack,
                  url: '/programs',
                  target: false
              }
          ]
};

export default programs;
